<template>
  <OrganizationsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('organization', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="createRoute"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </OrganizationsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon, TrashIcon } from 'vue-feather-icons'

import ORGANIZATIONS_CONNECTION_QUERY from '#/graphql/organizations/organizationsConnection.gql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const OrganizationsStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    OrganizationsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      perPage: 10,
      page: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    hasNextPage() {
      return this.page < this.totalPages - 1
    },
    hasPreviousPage() {
      return this.page > 0
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'organization'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createOrganization'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'a',
          title: this.$tc('name', 2),
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, type, name } = row
            if (!id || !type || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={name} />
          },
        },
        {
          field: 'alias',
          key: 'alias',
          title: this.$tc('alias', 1),
          align: 'center',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search alias"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        { field: 'member', key: 'member', title: this.$tc('member', 2), align: 'center' },
        { field: 'asset', key: 'asset', title: this.$tc('asset', 2), align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <div on-click={() => this.confirmDelete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
                <div on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(org => {
        return {
          name: org.name,
          type: org.__typename,
          alias: org.alias,
          member: org.memberships.length,
          asset: org.assets.length,
          rowKey: org.id,
          id: org.id,
        }
      })
      return tableData
    },
  },
  methods: {
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    confirmDelete(model) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Organization',
          instance: model,
          labelKey: 'name',
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async delete(model) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deleteOrganization(where: { id: $id }) {
              id
            }
          }
        `,
        variables: {
          id: model.id,
        },
      })
      if (res) {
        this.$root.$emit('closeOverlay')
        this.$emit('reload-models')
      }
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            OR: [
              {
                name_contains: s,
              },
              {
                alias_contains: s,
              },
            ],
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: ORGANIZATIONS_CONNECTION_QUERY,
      variables() {
        return {
          first: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'organizationsConnection.edges', []).map(edge => edge.node)
        this.count = get(data, 'organizationsConnection.aggregate.count', 0)
        this.totalCount = get(data, 'organizationsConnectionTotal.aggregate.count', 0)
      },
    },
  },
}
</script>
